import { useStaticQuery, graphql } from 'gatsby'

const useFreeConsultationMetadata = () => {
  const query = useStaticQuery(graphql`
    query FreeConsultationMetadata {
      bannerBG: file(
        relativePath: { eq: "free-consultation/banner/bg-reg.png" }
      ) {
        id
        ...FreeConsultationImageOptimize
      }
      bannerBGTablet: file(
        relativePath: { eq: "free-consultation/banner/bg-tablet.png" }
      ) {
        id
        ...FreeConsultationImageOptimize
      }

      contactTablet1: file(
        relativePath: { eq: "free-consultation/contact/tablet-1.png" }
      ) {
        id
        ...FreeConsultationImageOptimize
      }
      contactTablet2: file(
        relativePath: { eq: "free-consultation/contact/tablet-2.png" }
      ) {
        id
        ...FreeConsultationImageOptimize
      }
      contactRightImg: file(
        relativePath: { eq: "free-consultation/contact/right-img.jpg" }
      ) {
        id
        ...FreeConsultationImageOptimize
      }

      blogBGPhone: file(
        relativePath: { eq: "free-consultation/blog/bg-phone.jpg" }
      ) {
        id
        ...FreeConsultationImageOptimize
      }
    }

    fragment FreeConsultationImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useFreeConsultationMetadata
