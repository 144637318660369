import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Contact.module.scss'

const GradientTabletsCard = ({
  wrapperClassName,
  title,
  subtitle,
  ctaText,
  detailsGradient = 'bg-gradient-slider-300',
  imgContainerGradient = 'bg-gradient-blue-surf',
  tablet1Img,
  tablet2Img,
  tablet1Alt,
  tablet2Alt,
  titleClassName = 'subtitle-2',
  subtitleClassName = 'subtitle-5',
}) => {
  return (
    <div className={clsx(wrapperClassName, [cx.card])}>
      <div className={clsx(detailsGradient, [cx.details])}>
        <h5>
          <span className={titleClassName}>{title}</span>
          <span className={subtitleClassName}>{subtitle}</span>
        </h5>
        <Button large text={ctaText} />
      </div>
      <div className={clsx(imgContainerGradient, [cx.imgContainer])}>
        <ElementImage src={tablet2Img} alt={tablet2Alt} />
        <ElementImage src={tablet1Img} alt={tablet1Alt} />
      </div>
      <ElementImage src={tablet1Img} alt={tablet1Alt} />
    </div>
  )
}

const GradientImgCard = ({
  wrapperClassName,
  title,
  subtitle,
  ctaText,
  detailsGradient = 'bg-gradient-skys-the-limit',
  img,
  imgAlt,
  titleClassName = 'subtitle-2',
  subtitleClassName = 'subtitle-5',
}) => {
  return (
    <div className={clsx(wrapperClassName, [cx.card])}>
      <div className={clsx(detailsGradient, [cx.details])}>
        <h5>
          <span className={titleClassName}>{title}</span>
          <span className={subtitleClassName}>{parse(subtitle)}</span>
        </h5>
        <Button large text={ctaText} />
      </div>
      <div className={cx.imgContainer}>
        <ElementImage src={img} alt={imgAlt} />
      </div>
    </div>
  )
}

const Contact = ({
  wrapperClassName,
  heading,
  subheading,
  email,
  leftTitle,
  leftSubtitle,
  leftCta,
  rightTitle,
  rightSubtitle,
  rightCta,
  rightImg,
  tablet1Img,
  tablet2Img,
  tablet1Alt,
  tablet2Alt,
  imgAlt,
  headingClassName = 'heading-3',
  subheadingClassName = 'subtitle-3',
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <h3 className={cx.heading}>
        <div className={subheadingClassName}>
          <span>{parse(subheading)}</span>
          <span>{email}</span>
        </div>
        <div className={headingClassName}>{heading}</div>
      </h3>

      <div className={cx.container}>
        <GradientTabletsCard
          wrapperClassName={cx.gradientTabletsCard}
          title={leftTitle}
          subtitle={leftSubtitle}
          ctaText={leftCta}
          tablet1Img={tablet1Img}
          tablet2Img={tablet2Img}
          tablet1Alt={tablet1Alt}
          tablet2Alt={tablet2Alt}
        />
        <GradientImgCard
          wrapperClassName={cx.gradientImgCard}
          title={rightTitle}
          subtitle={rightSubtitle}
          ctaText={rightCta}
          img={rightImg}
          imgAlt={imgAlt}
        />
      </div>
    </section>
  )
}

Contact.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  email: PropTypes.string,
  leftTitle: PropTypes.string,
  leftSubtitle: PropTypes.string,
  leftCta: PropTypes.string,
  rightTitle: PropTypes.string,
  rightSubtitle: PropTypes.string,
  rightCta: PropTypes.string,
  rightImg: PropTypes.string,
  tablet1: PropTypes.string,
  tablet2: PropTypes.string,
  tablet1Alt: PropTypes.string,
  tablet2Alt: PropTypes.string,
  imgAlt: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
}

export default Contact
