import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import ConsultationBanner from '@components/global/banner/ConsultationBanner'
import { Contact } from '@components/pages/free-consultation'
import BlogPostsV3 from '@components/global/blog/BlogPostsV3'

import {
  pageData as page,
  bannerData,
  contactData,
} from '@src/data/FreeConsultation'
import useFreeConsultationMetadata from '@hooks/free-consultation-metadata'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './FreeConsultationForm.module.scss'

const FreeConsultationForm = () => {
  const {
    bannerBG,
    bannerBGTablet,
    contactTablet1,
    contactTablet2,
    contactRightImg,
    blogBGPhone,
  } = useFreeConsultationMetadata()
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767
  const isPhoneMedia = useMedia(media.phone)

  return (
    <LayoutInnerPage hasWhiteTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <ConsultationBanner
        wrapperClassName={cx.banner}
        breadcrumbs={page.breadcrumbs}
        bg={extractImage(isDesktop ? bannerBG : bannerBGTablet)}
        heading={bannerData.heading.text}
        subheading1={bannerData.subheading1.text}
        subheading2={bannerData.subheading2.text}
        stressSubheading={bannerData.stressSubheading.text}
        formHeading={bannerData.form.heading}
        formButtonText={bannerData.form.buttonText}
      />
      <Contact
        heading={contactData.heading.text}
        subheading={contactData.subheading.text}
        email={contactData.subheading.email}
        leftTitle={contactData.items[0].title}
        leftSubtitle={contactData.items[0].subtitle}
        leftCta={contactData.items[0].cta}
        tablet1Alt={contactData.items[0].tablet1Alt}
        tablet2Alt={contactData.items[0].tablet2Alt}
        rightTitle={contactData.items[1].title}
        rightSubtitle={contactData.items[1].subtitle}
        rightCta={
          isPhoneMedia
            ? contactData.items[1].ctaPhone
            : contactData.items[1].cta
        }
        imgAlt={contactData.items[1].imgAlt}
        tablet1Img={extractImage(contactTablet1)}
        tablet2Img={extractImage(contactTablet2)}
        rightImg={extractImage(contactRightImg)}
      />
      <BlogPostsV3
        wrapperClassName={cx.blog}
        title={page.blogTitle}
        background={extractImage(isPhone && blogBGPhone)}
      />
    </LayoutInnerPage>
  )
}

export default FreeConsultationForm
