import React from 'react'

export const pageData = {
  title: 'Free Consultation',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    { text: 'Free Consultation', url: '/free-consultation-form/' },
  ],
  uri: '/free-consultation-form/',
  blogTitle: 'READ UP TO LEVEL UP YOUR BUSINESS',
}

export const bannerData = {
  heading: {
    text: 'We’re All Ears!',
    className: 'heading-1',
  },
  subheading1: {
    text: "LET'S FIND THE PERFECT SOLUTION FOR YOU",
    className: 'subtitle-7',
  },
  subheading2: {
    text:
      'Tell us your challenges so our Web Marketing Strategists can give you expert advice.',
    className: 'subtitle-3',
  },
  stressSubheading: {
    text: 'No pressure. No charge. No risk.',
    className: 'subtitle-4',
  },
  form: {
    heading: 'Book Your Complimentary Consultation',
    buttonText: 'I WANT FREE EXPERT ADVICE',
  },
}

export const contactData = {
  heading: {
    text: 'Or By Calling 800.979.5799',
    className: 'heading-3',
  },
  subheading: {
    text: 'You can also reach our<br/> Web Marketing Strategists by emailing',
    email: ' info@agentimage.com',
    className: 'subtitle-3',
  },
  items: [
    {
      title: 'Compare Websites',
      subtitle:
        'Whether you’re a rookie or a rockstar, we’ve got a website design package for you.',
      cta: 'Compare now',
      tablet1Alt: 'Dianna Chen screenshot',
      tablet2Alt: 'Gary Gold screenshot',
    },
    {
      title: 'Frequently Asked Questions',
      subtitle: 'Got questions?<br/> We’ve got answers!',
      cta: 'READ OUR FAQ',
      ctaPhone: 'Browse Our FAQ',
      imgAlt: 'FAQs and meeting',
    },
  ],
}
